const geo = {
  getGeoLocation: async () => {
    const HOST_NAME =
      process.env.NEXT_PUBLIC_WEBSITE_HOST || "citizenshipper.com"
    const result = await fetch("https://api.ipgeolocation.io/ipgeo", {
      headers: { Origin: `${HOST_NAME}/` },
    })
    if (!result.ok) {
      console.log("Fetching geolocation failed", result)
      return null
    }
    const data = await result.json()
    return JSON.stringify(data)
  },
}

export default geo
